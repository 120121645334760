import React, { useCallback, useContext } from "react";

import { useLocation } from "@reach/router";

import LeadForm from "@/components/common/leadForm";
import PromoBlock from "@/components/common/promoBlock";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";

import BulletLinkBlock from "../bulletLinkBlock";
import Constructor from "../constructor";
import { convertToLeadFormDates, convertToLeadFormEmails } from "../constructor/utils";
import LabelLinkBlock from "../labelLinkBlock";
import LinkBlock from "../linkBlock";
import { LinkUrlPrefixEnum } from "../types";
import { getLinkBlockObject, shouldLinkBeBullet } from "../utils";

import { GoroskopProps } from "./types";

import "../styles.scss";
import "./styles.scss";

import useGoroskop from "./useGoroskop";

import BlurredContentHoc from "@/components/common/blurredContent";

import { birthdateItems, emailItems } from "./constants";

const Goroskop = ({ pageContext }: GoroskopProps) => {
  const {
    seoId,
    title = "Статья на Lunaro",
    ogTitle,
    description = "Статья на Lunaro",
    textTop,
    textMiddle,
    textBottom,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    goroskopLinks,
    autolinkedGoroskopLinks,
    url,
    expertsInitial,
    zone,
    commonTextTopBottomZone,
    commonTextMiddleBottomZone,
    commonBottomZone,
  } = pageContext;

  const fieldItems = [...emailItems, ...birthdateItems];

  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={url}
        imageUrl={`${textTop}${textMiddle}${textBottom}`?.match(/src\s*=\s*"(.+?)"/)?.[1]}
      />
      <PromoBlock />
      <div className="bg--white goroskop">
        <div className="page-width">
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName}
            breadcrumbs={breadcrumbs}
            urlPrefix={BreadcrumbUrlPrefixEnum.Goroskop}
          />
          <h1 itemProp="headline" className="goroskop__headline">
            {header || ""}
          </h1>
          <BlurredContentHoc
            fieldItems={fieldItems}
            textTop={textTop}
            textMiddle={textMiddle}
            textBottom={textBottom}
            textClassName="seo-typography"
          >
            <>
              {!!textTop && (
                <div
                  className="seo-typography"
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromDangerousTags(textTop || ""),
                  }}
                />
              )}
              <Constructor zone={commonTextTopBottomZone} expertsInitial={expertsInitial} />
              {!!textMiddle && (
                <div
                  className="seo-typography"
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromDangerousTags(textMiddle || ""),
                  }}
                />
              )}
              <Constructor zone={commonTextMiddleBottomZone} expertsInitial={expertsInitial} />
              {!!goroskopLinks?.length && (
                <LinkBlock links={getLinkBlockObject(goroskopLinks, LinkUrlPrefixEnum.Goroskop)} />
              )}
              {!!textBottom && (
                <div
                  className="seo-typography"
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromDangerousTags(textBottom || ""),
                  }}
                />
              )}
            </>
          </BlurredContentHoc>
          <Constructor zone={zone} expertsInitial={expertsInitial} />
          <Constructor zone={commonBottomZone} expertsInitial={expertsInitial} />
          {!!autolinkedGoroskopLinks?.length && (
            <>
              <h2 className="goroskop__more">Смотрите ещё</h2>
              {shouldLinkBeBullet(autolinkedGoroskopLinks?.length ?? 0) ? (
                <BulletLinkBlock
                  links={autolinkedGoroskopLinks}
                  urlPrefix={LinkUrlPrefixEnum.Goroskop}
                />
              ) : (
                <LabelLinkBlock
                  links={autolinkedGoroskopLinks}
                  urlPrefix={LinkUrlPrefixEnum.Goroskop}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Goroskop;
